<template>
  <div class="c-app flex-row align-items-center" :class="{ 'c-dark-theme': $store.state.darkMode }">
    <CContainer>
      <CRow class="justify-content-center">
        <CCol md="8">
          <CCardGroup>
            <CCard class="p-3">
              <CCardBody>
                <CForm @submit="onSubmit">
                  <CImg src="img/smart-pol-logo-v1.png" width="80%"/>
                  <p class="ml-2 font-sm text-mute text-warning"><b>스마트 유류고</b> 로그인</p>
                  <CInput
                    v-model="userId"
                    placeholder="아이디"
                    autocomplete="off"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-user"/></template>
                  </CInput>
                  <CInput
                    v-model="userPwd"
                    placeholder="비밀번호"
                    type="password"
                    autocomplete="off"
                    aria-required="true"
                    required
                  >
                    <template #prepend-content><CIcon name="cil-lock-locked"/></template>
                  </CInput>
                  <CRow>
                    <CCol col="12" class="text-right">
                      <CButton type="submit" color="warning" class="px-4" :disabled="isProcessing || userPwd.length < 6" block>
                        <CSpinner size="sm" type="grow" v-if="isProcessing"></CSpinner>
                        <strong>LOGIN</strong>
                      </CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </CCardBody>
              <CCardFooter class="bg-danger text-white" v-show="message !== ''">
                <strong>{{message}}</strong>
              </CCardFooter>
            </CCard>
            <CCard
              color="primary"
              text-color="white"
              class="text-center py-5 d-md-down-none"
              body-wrapper
            >
              <CCardBody>
                <div>
                  <h5>ATG Management System </h5>
                  <i class="text-muted font-sm">version 2.0
                    <CBadge color="info">R{{releaseNum}}</CBadge></i> <br/>
                  <hr/>
                  <h5 class="text-warning">관계자 외 접근을 제한합니다.</h5>
                  <b class="text-danger text-sm">사용자의 모든 활동은 기록됩니다.</b>
                  <hr/>
                  <i class="text-info">smart</i> <strong class="text-warning">POL</strong>
                </div>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
    <CModal
      show
      title="[NOTICE] 스마트 유류고 프로그램 사용 공지"
      size="lg"
      centered
      :footer="false"
      color="info"
      border-color="info"
      v-if="showModal"
    >
      <div class="modal-body">
        <p class="font-weight-bold">Smart POL v2.0 <b-badge>R{{ releaseNum }}</b-badge></p>
        <ol>
          <li>인가된 사용자 이외는 사용을 금지합니다.</li>
          <li>보안에 저촉되는 행위는 관련 법령에 의해 처벌됩니다.</li>
          <li>
            시스템의 개인정보를 불법으로 이용 및 제공할 경우에는 "개인정보보호법 제18조,
            제19조, 제26조에 따라 개인정보를 목적 외로 이용하거나 제공할 경우 5년 이하의 징역 또는 5천만원 이하 벌금이 부가됩니다.
          </li>
        </ol>
      </div>
      <template #footer>
        <CButton color="primary" @click="agreeToTerms">동의</CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { alertError, alertWarn, initBase, sleep } from '@/common/utils';

export default {
  name: 'Login',
  props: {
    message: { type: String, default: '' },
    routeTo: { type: String, default: '' },
    code: { type: String, default: '' },
    query: { type: Object, default: null },
  },
  data() {
    return {
      userId: '',
      userPwd: '',
      isProcessing: false,
      showModal: true, // Show the modal initially
      releaseNum: 2
    };
  },
  async created() {
    console.log("####### Login.created() message--->", this.message);

    if (localStorage.user) {
      // console.log("######## Login.created() ------------ localStorage.user: ", localStorage.user);
      try {
        const rs = await this.$store.dispatch('setLoginState', JSON.parse(localStorage.user));
        if (rs) await this.redirect();
      } catch (err) {
        console.error("[CREATED] -------------- err: ", err);
      }
    }
  },
  methods: {
    async onSubmit(evt) {
      evt.preventDefault();
      console.debug("------------------> Login.onSubmit()..............");
      try {


        this.isProcessing = true;
        const encodedPwd = btoa(this.userPwd);
        const rs = await this.$store.dispatch('LOGIN', { userId: this.userId, userPwd: encodedPwd });

        // console.log("---- Login.onSubmit ......$store.LOGIN result: ", rs);

        if (rs.code === 200) {
          if (this.$store.state.isAuth) {
            // console.log("after --------------if--------- isAuth ", this.$store.state.isAuth);
            await this.redirect();
          }
        } else if (rs.code === 3209) {
          await alertWarn(this.$bvModal, "비밀번호가 만료 되었습니다. 변경이 필요합니다.", "비밀번호 만료");
          await this.$router.push({ name: "PasswordReset", params: { userId: this.userId } });
        } else if (rs.code === 3201) {
          await alertWarn(this.$bvModal, "비밀번호가 초기화 되었습니다. 변경이 필요합니다.", "비밀번호 초기화");
          await this.$router.push({ name: "PasswordReset", params: { userId: this.userId } });
        } else if (rs.code === 290) {
          await alertWarn(this.$bvModal, "중복 로그인 입니다.이전 세션은 로그아웃됩니다.", "중복 로그인 경고");
          await this.redirect();
        } else {
          // console.log('login else case ----> ', rs);
          await alertError(this.$bvModal, rs.message, rs.code);
          return false;
        }
      } catch (err) {
        console.log("onSubmit result----------------err:", err);
        await alertError(this.$bvModal, err.message, "9999");
      } finally {
        this.isProcessing = false;
      }
    },
    async redirect() {
      try {
        // console.log("redirect() ===============> isAuth: ", this.$store.state.isAuth);
        let routePath;
        const role = this.$store.state.user.role;
        // console.log("redirect() ===============> role: ", role);
        switch (role) {
          case 'SA':
          case 'SM':
            routePath = '/dashboard';
            break;
          case 'SS':
            routePath = '/monitor/tank';
            this.$store.state.sidebarMinimize = true;
            break;
          default:
            routePath = '/login';
            break;
        }
        // console.log("####### Login.redirect ----> " + routePath);
        await this.$router.push({ path: routePath }, () => { }, () => {
          console.error('routing aborted to ...' + routePath);
        });
      } catch (err) {
        console.error(err);
      }
    },
    agreeToTerms() {
      this.showModal = false;
    }
  }
};
</script>
